<template>
  <layout width="420" :dividers="false">
    <template slot="header">
      {{ title }}
    </template>

    <template>
      <v-form>
        <v-select
          v-if="select && !autocomplete"
          v-model="state"
          :clearable="!!select.clearable"
          :items="select.items"
          :item-text="select.itemText"
          :item-value="select.itemValue"
          :label="valueName"
        />
        <v-autocomplete
          v-else-if="select && autocomplete"
          v-model="state"
          :items="select.items"
          :item-text="select.itemText"
          :item-value="select.itemValue"
          :label="valueName"
        />
        <template v-else>
          <v-text-field
            v-if="isNumber(typeId)"
            v-model.number="state"
            type="number"
            :label="valueName"
          />
          <v-text-field v-else v-model="state" :label="valueName" />
        </template>
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="handleConfirm"
      >
        {{ confirmText }}
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import layout from './PopupLayoutDefault';
import { isNumber } from '@/utils/getCurrentType';
export default {
  name: 'PopupValueEdit',
  components: {
    layout
  },
  props: {
    title: {
      type: String,
      default: 'Edit value'
    },
    valueName: {
      type: String,
      default: 'Name'
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    confirmText: {
      type: String,
      default: 'Save'
    },
    onConfirm: {
      type: Function,
      default: () => {}
    },
    select: {
      type: Object,
      default: undefined
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const state = ref(null);
    const handleCancel = () => {
      emit('close');
    };
    const handleConfirm = async () => {
      try {
        loading.value = true;
        await props.onConfirm(state.value);
        loading.value = false;
        emit('close');
      } finally {
        loading.value = false;
      }
    };
    onMounted(() => {
      state.value = props.value;
    });
    return {
      isNumber,
      handleConfirm,
      handleCancel,
      loading,
      state
    };
  }
};
</script>

<style lang="sass" scoped>
::v-deep
  .form-subheader:first-child
    margin-top: -16px
</style>
